import React, { FC } from 'react';
import { graphql } from 'gatsby';
import { Col, Container, Row } from 'react-bootstrap';

import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';
import UniversalImage from 'components/common/UniversalImage';
import IconCustom from 'components/common/IconCustom';
import NFButton from 'components/common/NFButton';
import useScreenRecognition from 'hooks/useScreenRecognition';
import { removeHTMLTags } from 'utils/helpers';

import { PromotionHeroBannerProps } from './models';

import './PromotionHeroBanner.scss';

const PromotionHeroBanner: FC<PromotionHeroBannerProps> = ({
  title,
  firstButtonTitle,
  firstButtonAnchor,
  secondButtonTitle,
  secondButtonAnchor,
  heroImage,
  arrowAnchor,
  arrowAnchorBackground,
  link,
}) => {
  const { isMobile } = useScreenRecognition();
  const handleAnchorClick = (anchor) => {
    const element = document.querySelector(anchor);
    element?.scrollIntoView({ behavior: 'smooth' });
    window.history.pushState(null, '', anchor);
  };

  const handleClick = (event: React.MouseEvent<HTMLAnchorElement>, anchor) => {
    event.preventDefault();
    window.requestAnimationFrame(() => handleAnchorClick(anchor));
  };

  return (
    <section className="hero" data-testid="hero">
      <Container fluid>
        <Row className="flex-row-reverse">
          {!isMobile ? (
            <Col sm="12" md="6" className="hero__image">
              <UniversalImage
                img={heroImage}
                imageAlt={removeHTMLTags(title)}
                wrapperClassName="hero__image-frame"
              />
            </Col>
          ) : null}
          <Col sm="12" md="6" className="hero__content mt-4">
            <DangerouslySetInnerHtml className="hero__title" html={title} />
            {isMobile ? (
              <UniversalImage
                img={heroImage}
                imageAlt={removeHTMLTags(title)}
                wrapperClassName="hero__image-frame"
              />
            ) : null}
            <div className="hero__btn-row">
              {link?.[0]?.name ? (
                <NFButton
                  link={link[0]?.url}
                  target={link[0]?.target}
                  btnText={link[0].name}
                  ariaLabel={link[0].name}
                />
              ) : null}
              {firstButtonTitle ? (
                <a
                  href={firstButtonAnchor}
                  className="nf-btn my-3"
                  onClick={(e) => handleClick(e, firstButtonAnchor)}
                >
                  {firstButtonTitle}
                </a>
              ) : null}
              {secondButtonTitle ? (
                <a
                  href={secondButtonAnchor}
                  className="nf-btn nf-btn-white"
                  onClick={(e) => handleClick(e, secondButtonAnchor)}
                >
                  {secondButtonTitle}
                </a>
              ) : null}
            </div>
          </Col>
        </Row>
      </Container>
      {arrowAnchor ? (
        <a
          href={arrowAnchor}
          data-testid="arrow anchor"
          onClick={(e) => handleClick(e, arrowAnchor)}
          className="hero__arrow"
        >
          <IconCustom icon="arrow-anchor" />
          <DangerouslySetInnerHtml
            className="hero__arrow-background"
            html={arrowAnchorBackground?.svg?.content}
          />
        </a>
      ) : null}
    </section>
  );
};

export default PromotionHeroBanner;

export const query = graphql`
  fragment FragmentPromotionHeroBanner on IPromotionHeroBanner {
    title
    firstButtonTitle
    firstButtonAnchor
    secondButtonTitle
    secondButtonAnchor
    arrowAnchor
    heroImage {
      gatsbyImage {
        childImageSharp {
          fluid(maxWidth: 695) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
    arrowAnchorBackground {
      fallbackUrl
      svg {
        content
      }
    }
    link {
      name
      target
      url
    }
  }
`;
