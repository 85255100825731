import React, { FC } from 'react';
import { graphql } from 'gatsby';
import classNames from 'classnames';
import Container from 'react-bootstrap/Container';

import useScreenRecognition from 'hooks/useScreenRecognition';
import UniversalImage from 'components/common/UniversalImage';
import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';
import NFButton from 'components/common/NFButton';
import { IPropsNFButton } from 'components/common/NFButton/model';
import { parseNFButton } from 'components/common/NFButton/parsers';

import { IBannerColorComponent } from './model';

import './BannerColor.scss';

const BannerColor: FC<IBannerColorComponent> = ({
  title,
  text,
  pageBannerButtonData,
  colorImage,
  colorImageMobile,
  imageAlt,
  bannerBackground,
  bannerSize,
  phoneImagePosition,
  desktopImagePosition,
  labelText,
  labelBackground,
  colorText,
}) => {
  const { isMobile } = useScreenRecognition({ isMobile: true });
  const btn: IPropsNFButton = pageBannerButtonData?.length
    ? parseNFButton(pageBannerButtonData[0].properties)
    : undefined;
  const image = colorImageMobile && isMobile ? colorImageMobile : colorImage || null;

  return (
    <section className={classNames('color-banner', {
      [`color-banner--${bannerBackground?.[0]}-bg`]: bannerBackground?.length,
      [`color-banner--${bannerSize?.[0]}`]: bannerSize?.length,
      [`color-banner--${phoneImagePosition?.[0]}-image`]: phoneImagePosition?.length,
      [`color-banner--${desktopImagePosition?.[0]}-image`]: desktopImagePosition?.length,
    })} data-test="ColorBanner">
      <div>
        <Container fluid className="color-banner__container">
          <div className="color-banner__content">
            <DangerouslySetInnerHtml className="color-banner__title" html={title} />
            {colorText?.length ? (
              <p className="color-banner__color-text">
                {colorText.map(({ value }) => (
                  <span key={value}>{value}</span>
                ))}
              </p>
            ) : null}
            {labelText ? (
              <div className="color-banner__label-wrapper">
                <div className="color-banner__label">
                  <p className="color-banner__label-text">{labelText}</p>
                  {labelBackground ? (
                    <UniversalImage
                      img={labelBackground}
                      objectFitData={{ objectFit: 'contain' }}
                      wrapperClassName="color-banner__label-background"
                    />
                  ) : null}
                </div>
              </div>
            ) : null}
            <DangerouslySetInnerHtml className="color-banner__text" html={text} />
            {pageBannerButtonData?.length ? <NFButton {...btn} /> : null}
          </div>
          {image ? (
            <UniversalImage
              imageAlt={imageAlt}
              img={image}
              objectFitData={{ objectFit: 'contain' }}
              wrapperClassName="color-banner__image"
            />
          ) : null}
        </Container>
      </div>
    </section>
  );
};

export const query = graphql`
  fragment FragmentColorBanner on IColorBanner {
    title
    text
    pageBannerButtonData {
      properties {
        ...FragmentNFButton
      }
    }
    colorImage {
      fallbackUrl
      url
      gatsbyImage {
        childImageSharp {
          fluid(maxWidth: 700) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      svg {
        content
      }
    }
    colorImageMobile {
      fallbackUrl
      url
      gatsbyImage {
        childImageSharp {
          fluid(maxWidth: 700) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      svg {
        content
      }
    }
    imageAlt
    bannerBackground
    bannerSize
    phoneImagePosition
    desktopImagePosition
    colorText {
      value
    }
    labelText
    labelBackground {
      fallbackUrl
      url
      gatsbyImage {
        childImageSharp {
          fluid(maxWidth: 460) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      svg {
        content
      }
    }
  }
`;

export default BannerColor;
