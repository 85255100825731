import React, { FC } from 'react';

import { IPainHomeBannerComponentProps } from './model';
import 'components/PainHomeBanner/PainHomeBanner.scss';
import { Link, graphql } from 'gatsby';
import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';
import UniversalImage from 'components/common/UniversalImage';
import useScreenRecognition from 'hooks/useScreenRecognition';

const PainHomeBanner: FC<IPainHomeBannerComponentProps> = ({ painHomeBannerTitleRte, painHomeBannerBackgroundImage, painHomeBannerLogo, painHomeBannerText, painHomeBannerHeroImage, painHomeBannerLink }) => {
  const { isMobile } = useScreenRecognition({ isMobile: true });
  return (
    <div className='pain-home-banner'>
      <div className='pain-home-banner__background-image'>
        <UniversalImage img={painHomeBannerBackgroundImage} />
      </div>
      <UniversalImage
        wrapperClassName='pain-home-banner__hero-image'
        objectFitData={{ objectFit: "cover" }}
        img={painHomeBannerHeroImage} />
      <div className="pain-home-banner__content">
        <div className="pain-home-banner__top-content">
          <UniversalImage wrapperClassName='pain-home-banner__logo' img={painHomeBannerLogo} />
          <DangerouslySetInnerHtml className="pain-home-banner__heading" element="h1" html={painHomeBannerTitleRte} />
        </div>
        <div className='pain-home-banner__bottom-content'>
          <Link className='pain-home-banner__link' to={painHomeBannerLink[0].properties.link[0].url}>{painHomeBannerLink[0].properties.text}</Link>
          <div className='pain-home-banner__text'>
            <DangerouslySetInnerHtml html={painHomeBannerText} />
          </div>
        </div>
      </div>

    </div>
  );
};

export const query = graphql`
  fragment FragmentPainHomeBanner on IPainHomeBanner {
    painHomeBannerTitleRte
    painHomeBannerBackgroundImage: painHomeBannerBackgroundImage {
      fallbackUrl
      gatsbyImage {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
    painHomeBannerHeroImage: painHomeBannerHeroImage {
      fallbackUrl
      gatsbyImage {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
    painHomeBannerLogo: painHomeBannerLogo {
      fallbackUrl
      gatsbyImage {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
    painHomeBannerText
    painHomeBannerLink {
      properties {
        ...FragmentNFButton
      }
    }
  }
`;


export default PainHomeBanner;
