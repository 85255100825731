import React, { FC } from 'react';
import { graphql } from 'gatsby';

import UniversalImage from 'components/common/UniversalImage';

import { IPainBanner } from './model';

import './PainBanner.scss';

const PainBanner: FC<IPainBanner> = ({ title, image, imageAlt }) => (
  <section className="pain-banner">
    <div className="pain-banner__content">
      <h1 className="pain-banner__title">{title}</h1>
    </div>
    {image ? (
      <div className="pain-banner__image">
        <UniversalImage img={image} imageAlt={imageAlt} />
      </div>
    ) : null}
  </section>
);

export const query = graphql`
  fragment FragmentPainBanner on IPainBanner {
    title
    bannerImage: image {
      fallbackUrl
      gatsbyImage {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
    imageAlt
  }
`;

export default PainBanner;
