import React, { FC } from 'react';
import { Container } from 'react-bootstrap';

import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';
import Video from 'components/common/Video';
import SimpleSection from '../SimpleSection';

import './VideoSection.scss';

export interface IVideoSectionComponentProps extends PodcastsPageTypes.IVideoSection {}

const VideoSection: FC<IVideoSectionComponentProps> = ({
  sectionAriaLabel,
  section,
  videoBlock,
}) => {
  const containerColumns = {
    md: { span: 10, offset: 1 },
  };

  return (
    <SimpleSection
      {...section?.[0]?.properties}
      columns={containerColumns}
      sectionAriaLabel={sectionAriaLabel}
    >
      <ul className="video-section__video-list">
        {videoBlock.map(
          ({
            properties: {
              videoTitle,
              videoDescription,
              btnPlay,
              previewImg,
              videoLink,
              btnPlayAriaLabel,
              isVideoRounded,
            },
          }) => (
            <li>
              {videoTitle ? (
                <DangerouslySetInnerHtml html={videoTitle} className="video-section__video-title" />
              ) : null}
              {videoDescription ? (
                <DangerouslySetInnerHtml
                  html={videoDescription}
                  className="video-section__video-description"
                />
              ) : null}
              <Container fluid>
                <Video
                  btnPlay={btnPlay.svg.content}
                  btnPlayAriaLabel={btnPlayAriaLabel}
                  previewImg={previewImg?.[0]?.properties || previewImg}
                  video={videoLink}
                  isVideoRounded={isVideoRounded}
                />
              </Container>
            </li>
          )
        )}
      </ul>
    </SimpleSection>
  );
};

export default VideoSection;
